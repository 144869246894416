import React, { useState } from "react";
const topics = [
  {
    title: "Problems on Alligation or Mixture",
    path: "/problems-on-alligation-or-mixture",
    solved: 17,
    total: 30,
    formulas: [
      "Alligation: It is the rule that enables us to find the ratio in which two or more ingredients at the given price must be mixed to produce a mixture of desired price.",
      "Mean Price: cost of a unit quantity of the mixture is called the mean price.",
      "Rule of Alligation: Quantity of cheaper ingredient = (Price of dearer ingredient - Mean price) / (Mean price - Price of cheaper ingredient)",
      "Rule of Alligation: Quantity of dearer ingredient = (Mean price - Price of cheaper ingredient) / (Price of dearer ingredient - Mean price)",
      "(Cheaper quantity) : (Dearer quantity) = (d - m) : (m - c).",
      "Suppose a container contains x of liquid from which y units are taken out and replaced by water. After n operations, the quantity of pure liquid = x * (1 - y/x)^n ",
    ],
  },
  {
    title: "Problems on Average",
    path: "/problems-on-average",
    solved: 6,
    total: 12,
    formulas: [
      "Average = Sum of observations / Number of observations ",
      " Average Speed: Suppose a man covers a certain distance at x kmph and an equal distance at y kmph.Then, the average speed during the whole journey is (2xy km/hr) / (x + y)",
    ],
  },
  {
    title: "Problems on Bankers Discount",
    path: "/problems-on-bankers-discount",
    solved: 11,
    total: 18,
    formulas: [
      "Suppose a merchant A buys goods worth, say Rs. 10,000 from another merchant B at a credit of say 5 months. Then, B prepares a bill, called the bill of exchange. A signs this bill and allows B to withdraw the amount from his bank account after exactly 5 months.",
      "The date exactly after 5 months is called nominally due date. Three days (known as grace days) are added to it get a date, known as legally due date.",
      "Suppose B wants to have the money before the legally due date. Then he can have the money from the banker or a broker, who deducts S.I. on the face vale (i.e., Rs. 10,000 in this case) for the period from the date on which the bill was discounted (i.e., paid by the banker) and the legally due date. This amount is know as Banker's Discount (B.D.).",
      "Thus, B.D. is the S.I. on the face value for the period from the date on which the bill was discounted and the legally due date.",
      "Banker's Gain (B.G.) = (B.D.) - (T.D.) for the unexpired time.",
      "B.D. = S.I. on bill for unexpired time: This formula states that the bill discount is equal to the simple interest charged on the face value of the bill for the remaining time (unexpired time) until its maturity.",
      "B.G. = (B.D.) - (T.D.) = S.I. on T.D.: This formula calculates the banker's gain. It's the difference between the bill discount (B.D.) and the true discount (T.D.). It essentially represents the simple interest earned by the bank on the true discount (present value) for the unexpired time.",
      "T.D. = P.W. x B.G. / (Rate x Time): This formula calculates the true discount (present value) based on the banker's gain (B.G.), present worth (P.W.), interest rate, and time.",
      "B.D. = Amount x Rate x Time / 100: This is a general formula for calculating the bill discount based on the total amount, interest rate, and time.",
      "T.D. = Amount x Rate x Time / (100 + (Rate x Time)): This formula calculates the true discount based on the total amount, interest rate, and time. It considers both the principal amount and the interest earned during the time period.",
      "Amount = B.D. x T.D. / (B.D. - T.D.): This formula calculates the total amount payable at maturity based on the bill discount and true discount.",
      "T.D. = B.G. x 100 / (Rate x Time): This formula is another way to calculate the true discount using the banker's gain, interest rate, and time.",
    ],
  },
  {
    title: "Problems on Boats and Streams",
    path: "/problems-on-boats-and-streams",
    solved: 15,
    total: 25,
    formulas: [
      "Downstream/Upstream: In water, the direction along the stream is called downstream. And, the direction against the stream is called upstream.",
      "If the speed of a boat in still water is u km/hr and the speed of the stream is v km/hr, then: Speed downstream = (u + v) km/hr. Speed upstream = (u - v) km/hr.",
      "If the speed downstream is a km/hr and the speed upstream is b km/hr, then: Speed in still water =	1/2	(a + b) km/hr. Rate of stream =	1/2	(a - b) km/hr. ",
    ],
  },
  {
    title: "Problems on Calendar",
    path: "/problems-on-calendar",
    solved: 11,
    total: 21,
    formulas: [
      "Odd Days: We are supposed to find the day of the week on a given date. For this, we use the concept of 'odd days'. In a given period, the number of days more than the complete weeks are called odd days.",
      "Leap Year: (i). Every year divisible by 4 is a leap year, if it is not a century. (ii). Every 4th century is a leap year and no other century is a leap year.",
      "Note: A leap year has 366 days.",
      "Ordinary Year: The year which is not a leap year is called an ordinary years. An ordinary year has 365 days.",
      "Counting of Odd Days: 1 ordinary year = 365 days = (52 weeks + 1 day.) 1 ordinary year has 1 odd day.",
      "1 leap year = 366 days = (52 weeks + 2 days), 1 leap year has 2 odd days.",
      "100 years = 76 ordinary years + 24 leap years = (76 x 1 + 24 x 2) odd days = 124 odd days. = (17 weeks + days)  5 odd days. Number of odd days in 100 years = 5.",
      "Number of odd days in 200 years = (5 x 2)  3 odd days.",
      "Number of odd days in 300 years = (5 x 3)  1 odd day.",
      "Number of odd days in 400 years = (5 x 4 + 1)  0 odd day.",
      "Similarly, each one of 800 years, 1200 years, 1600 years, 2000 years etc. has 0 odd days.",
    ],
  },
  {
    title: "Problems on Chain Rule",
    path: "/problems-on-chain-rule",
    solved: 11,
    total: 19,
    formulas: [
      "Direct Proportion: Two quantities are said to be directly proportional, if on the increase (or decrease) of the one, the other increases (or decreases) to the same extent.",
      "Eg. Cost is directly proportional to the number of articles. (More Articles, More Cost)",
      "Indirect Proportion: Two quantities are said to be indirectly proportional, if on the increase of the one, the orther decreases to the same extent and vice-versa.",
      "Eg. The time taken by a car is covering a certain distance is inversely proportional to the speed of the car. (More speed, Less is the time taken to cover a distance.)",
    ],
  },
  {
    title: "Problems on Clock",
    path: "/problems-on-clock",
    solved: 16,
    total: 23,
    formulas: [
      "Minute Spaces: The face or dial of watch is a circle whose circumference is divided into 60 equal parts, called minute spaces.",
      "Hour Hand and Minute Hand: A clock has two hands, the smaller one is called the hour hand or short hand while the larger one is called minute hand or long hand.",
      "In 60 minutes, the minute hand gains 55 minutes on the hour on the hour hand.",
      "In every hour, both the hands coincide once.",
      "The hands are in the same straight line when they are coincident or opposite to each other.",
      "When the two hands are at right angles, they are 15 minute spaces apart.",
      "When the hands are in opposite directions, they are 30 minute spaces apart.",
      "Angle traced by hour hand in 12 hrs = 360°",
      "Angle traced by minute hand in 60 min. = 360°.",
      "If a watch or a clock indicates 8.15, when the correct time is 8, it is said to be 15 minutes too fast. On the other hand, if it indicates 7.45, when the correct time is 8, it is said to be 15 minutes too slow.",
    ],
  },
  {
    title: "Problems on Compound Interest",
    path: "/problems-on-compound-interest",
    solved: 9,
    total: 18,
    formulas: [
      "Let Principal = P, Rate = R% per annum, Time = n years.",
      "When interest is compound Annually: Amount = P (1 + R/100)^n",
      "When interest is compounded Half-yearly: = P (1 + R/200)^2n",
      "When interest is compounded Quarterly: Amount = P (1 + R/400)^4n",
      "When interest is compounded Annually but time is in fraction, say 3 years. Amount = p (1 + R/100)^3 * (1 + (2/5R)/100))",
      "When Rates are different for different years, say R1%, R2%, R3% for 1st, 2nd and 3rd year respectively. Amount= P(1+R1/100)* (1+R2/100) * (1 + R3/100)",
      "Present worth of Rs. x due n years hence is given by: = x / (1 + R/100)^n",
    ],
  },
  {
    title: "Problems on H.C.F and L.C.M",
    path: "/problems-on-hcf-and-lcm",
    solved: 15,
    total: 35,
    formulas: [
      "Factors and Multiples:If number a divided another number b exactly, we say that a is a factor of b.In this case, b is called a multiple of a",
      "Factorization Method: Express the each one of the given numbers as the product of prime factors. The product of least powers of common prime factors gives H.C.F.",
      "Division Method (HCF): Suppose we have to find the H.C.F. of two given numbers, divide the larger by the smaller one. Now, divide the divisor by the remainder. Repeat the process of dividing the preceding number by the remainder last obtained till zero is obtained as remainder. The last divisor is required H.C.F.",
      "Finding the H.C.F. of more than two numbers: Suppose we have to find the H.C.F. of three numbers, then, H.C.F. of [(H.C.F. of any two) and (the third number)] gives the H.C.F. of three given number.",
      "The least number which is exactly divisible by each one of the given numbers is called their L.C.M.",
      "Factorization Method (LCM): Resolve each one of the given numbers into a product of prime factors. Then, L.C.M. is the product of highest powers of all the factors.",
      "Division Method (short-cut): Arrange the given numbers in a rwo in any order. Divide by a number which divided exactly at least two of the given numbers and carry forward the numbers which are not divisible. Repeat the above process till no two of the numbers are divisible by the same number except 1. The product of the divisors and the undivided numbers is the required L.C.M. of the given numbers.",
      "Product of two numbers = Product of their H.C.F. and L.C.M.",
      "Co-primes: Two numbers are said to be co-primes if their H.C.F. is 1.",
      "H.C.F. =	(H.C.F. of Numerators) / (L.C.M. of Denominators)",
      "L.C.M. =	(L.C.M. of Numerators) / (H.C.F. of Denominators)",
      "H.C.F. and L.C.M. of Decimal Fractions: In a given numbers, make the same number of decimal places by annexing zeros in some numbers, if necessary. Considering these numbers without decimal point, find H.C.F. or L.C.M. as the case may be. Now, in the result, mark off as many decimal places as are there in each of the given numbers.",
      "Comparison of Fractions: Find the L.C.M. of the denominators of the given fractions. Convert each of the fractions into an equivalent fraction with L.C.M as the denominator, by multiplying both the numerator and denominator by the same number. The resultant fraction with the greatest numerator is the greatest.",
    ],
  },
  {
    title: "Problems on Height and Distance",
    path: "/problems-on-height-and-distance",
    solved: 5,
    total: 10,
    formulas: [
      "Sine (sin): Perpendicular divided by Hypotenuse (sin θ = AB/OB).",
      "Cosine (cos): Base divided by Hypotenuse (cos θ = OA/OB).",
      "Tangent (tan): Perpendicular divided by Base (tan θ = AB/OA).",
      "Cosecant (csc): One divided by Sine (csc θ = 1/sin θ = OB/AB).",
      "Secant (sec): One divided by Cosine (sec θ = 1/cos θ = OB/OA).",
      "Cotangent (cot): One divided by Tangent (cot θ = 1/tan θ = OA/AB).",
      "Pythagorean Theorem: sin²θ + cos²θ = 1 (always true in right-angled triangles).",
      "1 + tan²θ = sec²θ",
      "1 + cot²θ = cosec²θ",
    ],
  },
  {
    title: "Problems on Logarithm",
    path: "/problems-on-logarithm",
    solved: 5,
    total: 15,
    formulas: [
      "Product Rule: logₐ(xy) = logₐx + logₐy",
      "Quotient Rule: logₐ(x/y) = logₐx - logₐy",
      "Log of 1: logₐ(1) = 0",
      "Power Rule: logₐ(x^n) = n * logₐx",
      "Change of Base Rule: logₐx = (log_bx) / (log_ba) (where b is any other positive base)",
      "Logarithms to base 10 are called common logarithms (often written as logx without a subscript).",
      "Characteristic: The integer part of the logarithm. For numbers greater than 1: Characteristic = number of digits to the left of the decimal point - 1 (excluding leading zeros).",
      "For numbers between 0 and 1: Characteristic = - (number of zeros before the first non-zero digit) - 1. It's written with a negative sign above the number (e.g., -1).",
    ],
  },
  {
    title: "Problems on Numbers",
    path: "/problems-on-numbers",
    solved: 10,
    total: 20,
    formulas: [
      "(a + b)(a - b) = (a² - b²)",
      "(a + b)² = (a² + 2ab + b²)",
      "(a - b)² = (a² - 2ab + b²) ",
      "(a + b + c)² = a² + b² + c² + 2(ab + bc + ca)",
      "(a³ + b³) = (a + b)(a² - ab + b²)",
      "(a³ - b³) = (a - b)(a² + ab + b²)",
      "(a³ + b³ + c³ - 3abc) = (a + b + c)(a² + b² + c² - ab - bc - ac) ",
      "a³ + b³ + c³ = 3abc  when a + b + c = 0.",
    ],
  },
  {
    title: "Problems on Odd Man Out and Series",
    path: "/problems-on-odd-man-out-and-series",
    solved: 10,
    total: 15,
    formulas: [],
  },
  {
    title: "Problems on Partnership",
    path: "/problems-on-partnership",
    solved: 14,
    total: 22,
    formulas: [
      "Partnership: When two or more than two persons run a business jointly, they are called partners and the deal is known as partnership.",
      "When investments of all the partners are for the same time, the gain or loss is distributed among the partners in the ratio of their investments. Suppose A and B invest Rs. x and Rs. y respectively for a year in a business, then at the end of the year: (A's share of profit) : (B's share of profit) = x : y.",
      "When investments are for different time periods, then equivalent capitals are calculated for a unit of time by taking (capital x number of units of time). Now gain or loss is divided in the ratio of these capitals. Suppose A invests Rs. x for p months and B invests Rs. y for q months then, (A's share of profit) : (B's share of profit)= xp : yq.",
      "Working and Sleeping Partners: A partner who manages the the business is known as a working partner and the one who simply invests the money is a sleeping partner.",
    ],
  },
  {
    title: "Problems on Percentage",
    path: "/problems-on-percentage",
    solved: 13,
    total: 28,
    formulas: [
      "If the price of a commodity increases by R%, then the reduction in consumption so as not to increase the expenditure is: (r/(100 + r)*100) %",
      "If the price of a commodity decreases by R%, then the increase in consumption so as not to decrease the expenditure is: (r/(100 - r)*100) %",
      "Population after n years = p (1 + r/100)^n",
      "Population n years ago = p/(1 + r/100)^n",
      "Value of the machine after n years = P (1 - R/100)^n (P = initial value, R = depreciation rate)",
      "Value of the machine n years ago = P / (1 - R/100)^n",
      "A is R% more than B: B is less than A by R/(100 + R).",
      "A is R% less than B: B is more than A by R/(100 - R).",
    ],
  },
  {
    title: "Problems on Permutation and Combination",
    path: "/problems-on-permutation-and-combination",
    solved: 19,
    total: 40,
    formulas: [
      "Let n be a positive integer. Then, factorial n, denoted n! is defined as: n! = n(n - 1)(n - 2) ... 3.2.1. ",
      "The different arrangements of a given number of things by taking some or all at a time, are called permutations.",
      "The formula nPr = n(n - 1)(n - 2) ... (n - r + 1) can be written more concisely using factorial notation as nPr = n! / (n - r)!",
      "n!/(p₁! p₂! ... pᵣ!), calculates the number of permutations of n objects when there are p₁ objects of one kind, p₂ objects of another kind, and so on, up to pr objects of the rth kind, where p₁ + p₂ + ... + pr = n (total number of objects).",
      "Each of the different groups or selections which can be formed by taking some or all of a number of objects is called a combination.",
      "nCr = n! / ((n - r)! * r!)",
      "nCn = 1 and nC0 = 1.",
    ],
  },
  {
    title: "Problems on Pipes and Cistern",
    path: "/problems-on-pipes-and-cistern",
    solved: 10,
    total: 20,
    formulas: [
      "A pipe connected with a tank or a cistern or a reservoir, that fills it, is known as an inlet.",
      "A pipe connected with a tank or cistern or reservoir, emptying it, is known as an outlet.",
      "If a pipe can fill a tank in x hours, the part filled in 1 hour is 1/x.pen_spark",
      "If a pipe can empty a tank in y hours, the part emptied in 1 hour is 1/y.",
      "If a pipe can empty a tank in y hours, then: part emptied in 1 hour =	1/y	",
      "If a pipe can fill a tank in x hours and another pipe can empty the full tank in y hours (where y > x), then on opening both the pipes, then net part filled in 1 hour = (1/x - 1/y)",
      "If a pipe can fill a tank in x hours and another pipe can empty the full tank in y hours (where x > y), then on opening both the pipes, then the net part emptied in 1 hour = (1/y - 1/x)",
      "When the filling time (x) is less than the emptying time (y), both pipes work together to fill the tank. The net part filled in 1 hour is calculated as 1 - (1/y), which simplifies to (y - x) / (xy).",
      "When the emptying time (y) is less than the filling time (x), both pipes work together to empty the tank. The net part emptied in 1 hour is calculated as 1 - (1/x), which simplifies to (x - y) / (xy).",
      "",
    ],
  },
  {
    title: "Problems on Probability",
    path: "/problems-on-probability",
    solved: 8,
    total: 12,
    formulas: [
      "Experiment: An action that produces well-defined results.",
      "Random Experiment: An experiment with known possible outcomes, but the specific outcome cannot be predicted beforehand. Examples: Coin toss, dice roll, card draw from a shuffled deck.",
      "Sample Space: The collection of all possible outcomes of an experiment. Examples: Coin toss (S = {H, T}), Dice roll (S = {1, 2, 3, 4, 5, 6}).",
      "Events: Any subset of the sample space.",
      "Probability: The likelihood of an event occurring.",
      "Probability is Calculated as the number of favorable outcomes (n(E)) divided by the total number of outcomes in the sample space (n(S)). P(E) = n(E) / n(S)",
      "P(S) = 1 (Probability of the entire sample space occurring is always 1).",
      "0 ≤ P(E) ≤ 1 (Probability of any event lies between 0 and 1, inclusive).",
      "P(Ø) = 0 (Probability of an empty event (no outcomes) is 0).",
      "For mutually exclusive events A and B (cannot occur together): P(A U B) = P(A) + P(B) (Probability of either A or B occurring).",
      "P(A̅) = 1 - P(A) (Probability of the complement of event A (not A) occurring).",
    ],
  },
  {
    title: "Problems on Profit and loss",
    path: "/problems-on-profit-and-loss",
    solved: 12,
    total: 24,
    formulas: [
      "Cost Price (C.P.): The price at which an item is bought.",
      "Selling Price (S.P.): The price at which an item is sold.",
      "Profit: When S.P. > C.P., the seller makes a profit.",
      "Loss: When S.P. < C.P., the seller incurs a loss.",
      "Profit: Profit = S.P. - C.P.",
      "Loss: Loss = C.P. - S.P. (Loss is always calculated based on the cost price).",
      "Gain Percentage: Gain% = (Profit x 100%) / C.P.",
      "Loss Percentage: Loss% = (Loss x 100%) / C.P.",
      "S.P. = (100 + Gain%) x C.P. / 100 (for profit)",
      "S.P. = (100 - Loss%) x C.P. / 100 (for loss)",
      "C.P. = (100 x S.P.) / (100 + Gain%) (for profit)",
      "C.P. = (100 x S.P.) / (100 - Loss%) (for loss)",
      "Gain of x% means S.P. is 135% of C.P. (100% + x%)",
      "Loss of x% means S.P. is 65% of C.P. (100% - x%)",
      "Combined Gain/Loss on Two Items: Selling two similar items with an equal percentage of gain and loss always results in a loss. The loss percentage is approximately (x^2)/2, where x is the common gain/loss percentage.",
      "False Weights: Selling with false weights leads to a gain of (Error x 100%) / (True Value - Error), where Error is the difference between the true and false weight.",
    ],
  },
  {
    title: "Problems on Races and Games",
    path: "/problems-on-races-and-games",
    solved: 6,
    total: 18,
    formulas: [
      "Races: A contest of speed in running, riding, driving, sailing or rowing is called a race.",
      "Race Course: The ground or path on which contests are made is called a race course.",
      "Starting Point: The point from which a race begins is known as a starting point.",
      "Winning Point or Goal: The point set to bound a race is called a winning point or a goal.",
      "Winner: The person who first reaches the winning point is called a winner.",
      "Dead Heat Race: If all the persons contesting a race reach the goal exactly at the same time, the race is said to be dead heat race.",
      "Start: Suppose A and B are two contestants in a race. If before the start of the race, A is at the starting point and B is ahead of A by 12 metres, then we say that 'A gives B, a start of 12 metres'. To cover a race of 100 metres in this case, A will have to cover 100 metres while B will have to cover only (100 - 12) = 88 metres. In a 100 race, 'A can give B 12 m' or 'A can give B a start of 12 m' or 'A beats B by 12 m' means that while A runs 100 m, B runs (100 - 12) = 88 m.",
      "Games: 'A game of 100, means that the person among the contestants who scores 100 points first is the winner'. If A scores 100 points while B scores only 80 points, then we say that 'A can give B 20 points'.",
    ],
  },
  {
    title: "Problems on Ratio and Proportion",
    path: "/problems-on-ratio-and-proportion",
    solved: 13,
    total: 22,
    formulas: [
      "The ratio of two quantities a and b in the same units, is the fraction  and we write it as a : b.",
      "In the ratio a : b, we call a as the first term or antecedent and b, the second term or consequent.",
      "Rule: The multiplication or division of each term of a ratio by the same non-zero number does not affect the ratio.",
      "Proportion: The equality of two ratios is called proportion.",
      "If a : b = c : d, we write a : b :: c : d and we say that a, b, c, d are in proportion.",
      "Here a and d are called extremes, while b and c are called mean terms. Product of means = Product of extremes. Thus, a : b :: c : d  (b x c) = (a x d).",
      "Fourth Proportional: If a : b = c : d, then d is called the fourth proportional to a, b, c.",
      "Third Proportional: a : b = c : d, then c is called the third proportion to a and b.",
      "Mean Proportional: Mean proportional between a and b is root(ab).",
      "Compounded Ratio: The ratio of the product of antecedents and the product of consequents from multiple ratios.",
      "Duplicate Ratio: The ratio of the squares of the terms in the original ratio (a² : b²).",
      "Sub-duplicate Ratio: The square root of the original ratio (√a : √b).",
      "Triplicate Ratio: The ratio of the cubes of the terms in the original ratio (a³ : b³).",
      "Sub-triplicate Ratio: The cube root of the original ratio (a^(1/3) : b^(1/3)).",
      "Direct Proportion: x is directly proportional to y if x = ky for some constant k (as y increases, x increases proportionally).",
      "Inverse Proportion: x is inversely proportional to y if xy = k for some constant k (as y increases, x decreases proportionally).",
    ],
  },
  {
    title: "Problems on Simple Interest",
    path: "/problems-on-simple-interest",
    solved: 8,
    total: 25,
    formulas: [
      "Principal: The money borrowed or lent out for a certain period is called the principal or the sum.",
      "Interest: Extra money paid for using other's money is called interest.",
      "If the interest on a sum borrowed for certain period is reckoned uniformly, then it is called simple interest.",
      "(i). Simple Interest (S.I.) = P x R x T / 100",
      "(ii). P = 100 x S.I. / R ;  R = 100 x S.I. / P ;  T = 100 x S.I. / (P x R)",
    ],
  },
  {
    title: "Problems on Simplification",
    path: "/problems-on-simplification",
    solved: 7,
    total: 14,
    formulas: [
      "Modulus (Absolute Value): The modulus (denoted by |a|) of a real number a represents its non-negative value.",
      "If a is positive (a > 0), then |a| = a. |5| = 5",
      "If a is negative (a < 0), then |a| = -a (absolute value makes the negative number positive). |-5| = -(-5) = 5",
      "Virnaculum (also called a bar or vinculum) is a horizontal line placed over an expression.",
      "It indicates that the entire expression under the bar should be evaluated before applying BODMAS.",
      "Example: 3 + |2 - 4| Following BODMAS, we first evaluate the expression under the bar: |-2| = 2.",
    ],
  },
  {
    title: "Problems on Stocks and Shares",
    path: "/problems-on-stocks-and-shares",
    solved: 9,
    total: 20,
    formulas: [
      "The total amount of money needed to run the company is called the stock capital.",
      "Shares or Stock: The whole capital is divided into small units, called shares or stock.",
      "The person who subscribes in shares or stock is called a share holder or stock holder.",
      "The annual profit distributed among share holders is called dividend. Dividend is paid annually as per share or as a percentage.",
      "The value of a share or stock printed on the share-certificate is called its Face Value or Nominal Value or Par Value.",
      "The stock of different companies are sold and bought in the open market through brokers at stock-exchanges. A share or stock is said to be: A.) At premium or Above par, if its market value is more than its face value.  B.) At par, if its market value is the same as its face value.  C.) At discount or Below par, if its market value is less than its face value.",
      "The broker's charge is called brokerage. (i)  When stock is purchased, brokerage is added to the cost price. (ii) When stock is sold, brokerage is subtracted from the selling price.",
      "Number of shares = Total investment / Face value per share.",
      "Income per share = Annual dividend / Face value per share.",
    ],
  },
  {
    title: "Problems on Time and Distance",
    path: "/problems-on-time-and-distance",
    solved: 15,
    total: 30,
    formulas: [
      "Speed (S) = Distance (D) / Time (T)",
      "Time (T) = Distance (D) / Speed (S)",
      "Distance (D) = Speed (S) x Time (T)",
      "A km/hr = A x (5/18) m/s.",
      "A m/s = A x (18/5) km/hr",
    ],
  },
  {
    title: "Problems on Time and Work",
    path: "/problems-on-time-and-work",
    solved: 7,
    total: 20,
    formulas: [
      "Work from Days: If A can do a piece of work in n days, then A's 1 day's work =	1/n",
      "Days from Work: If A's 1 day's work = 1/n	,	then A can finish the work in n days.",
      "Ratio: If A is thrice as good a workman as B, then: Ratio of work done by A and B = 3 : 1. Ratio of times taken by A and B to finish a work = 1 : 3.",
    ],
  },
  {
    title: "Problems on Trains",
    path: "/problems-on-trains",
    solved: 10,
    total: 20,
    formulas: [
      "km/hr to m/s: a km/hr = a x (5/18) m/s",
      "m/s to km/hr: a m/s = a x (18/5) km/hr",
      "Time taken by a train of length l metres to pass a pole or standing man or a signal post is equal to the time taken by the train to cover l metres.",
      "Time taken by a train of length l metres to pass a stationery object of length b metres is the time taken by the train to cover (l + b) metres.",
      "Suppose two trains or two objects bodies are moving in the same direction at u m/s and v m/s, where u > v, then their relative speed is = (u - v) m/s.",
      "Suppose two trains or two objects bodies are moving in opposite directions at u m/s and v m/s, then their relative speed is = (u + v) m/s.",
      "If two trains of length a metres and b metres are moving in opposite directions at u m/s and v m/s, then: The time taken by the trains to cross each other = (a + b) / (u + v) sec.",
      "If two trains of length a metres and b metres are moving in the same direction at u m/s and v m/s, then: The time taken by the faster train to cross the slower train = (a + b) / (u + v) sec.",
      "If two trains (or bodies) start at the same time from points A and B towards each other and after crossing they take a and b sec in reaching B and A respectively, then: (A's speed) : (B's speed) = (root(b) : root(a)).",
    ],
  },
  {
    title: "Problems on True Discount",
    path: "/problems-on-true-discount",
    solved: 14,
    total: 22,
    formulas: [
      "Present Worth (P.W.): The current value of a future sum of money, considering the time value of money and an interest rate.",
      "True Discount (T.D.): The amount saved by paying a certain sum now instead of a larger sum in the future (difference between sum due and its present worth). Represents the interest on the present worth.",
      "T.D. = Interest on P.W.",
      "Amount = P.W. + T.D. (Amount due is the sum of present worth and true discount).",
      "P.W. = (100 x Amount) / (100 + (R x T)) or (100 x T.D.) / (R x T)",
      "T.D. = (P.W. x R x T) / 100 or (Amount x R x T) / (100 + (R x T))",
      "Sum = (S.I.) x (T.D.) / ((S.I.) - (T.D.)) (Formula 3 is less common)",
      "(S.I.) - (T.D.) = S.I. on T.D. (Simple interest earned on the true discount)",
      " When the sum is put at compound interest, then P.W. =	Amount/ (1 +	R/ 100)^T",
    ],
  },
  {
    title: "Problems on Volume and Surface Area",
    path: "/problems-on-volume-and-surface-area",
    solved: 18,
    total: 30,
    formulas: [
      "Volume (cuboid): l x b x h (length x breadth x height)",
      "Surface Area (cuboid): 2(lb + bh + lh) (sum of areas of all six faces)",
      "Diagonal (cuboid): √(l² + b² + h²) (square root of the sum of squares of all sides)",
      "Volume (cube): a³ (edge length cubed)",
      "Surface Area (cube): 6a² (six faces with equal area)",
      "Diagonal (cube): √3a (square root of 3 times the edge length)",
      "Volume (cylinder): πr²h (pi x radius squared x height)",
      "Curved Surface Area (cylinder): 2πrh (circumference of the base x height)",
      "Total Surface Area (cylinder): 2πr(h + r) (curved surface area + 2 x area of the base)",
      "Slant Height (cone): √(h² + r²) (square root of the sum of squares of height and radius)",
      "Volume (cone): (1/3)πr²h (one-third pi x radius squared x height)",
      "Curved Surface Area (cone): πrl (slant height x radius of the base)",
      "Total Surface Area (cone): πrl + πr² (curved surface area + area of the base)",
      "Volume (sphere): (4/3)πr³ (four-thirds pi x radius cubed)",
      "Surface Area (sphere): 4πr² (four times pi x radius squared)",
      "Volume (Hemisphere): (2/3)πr³ (two-thirds pi x radius cubed) (half the volume of a whole sphere)",
      "Curved Surface Area (Hemisphere): 2πr² (surface area of the curved part)",
      "Total Surface Area (Hemisphere): 3πr² (curved surface area + area of the circular base)",
    ],
  },

  // { title: 'Problems on Decimal Fraction', path: '/problems-on-decimal-fraction', solved: 8, total: 15 },
  // { title: 'Problems on Square Root and Cube Root', path: '/problems-on-square-root-and-cube-root', solved: 12, total: 20 },
  // { title: 'Problems on Surds and Indices', path: '/problems-on-surds-and-indices', solved: 9, total: 18 },
];

export default topics;
